import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { isFulfilled } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import paths from "config/paths";
import classes from "./Questions.module.scss";
import Navbar from "components/Navbar";
import Button from "components/Button";
import Spinner from "components/Spinner";
import ProgressBar from "components/ProgressBar";
import InputRenderer from "components/InputRenderer";
import { IElement } from "models";
import { ReactComponent as DoneSvg } from "assets/images/done.svg";
import { Answer, CoverType, ICreateProductPayload, IQuestion, ITemplate } from "shared/lib/models";
import { map } from "shared/lib/utils";
import { RootState } from "store";
import { useAppDispatch } from "hooks";
import { getQuestionsThunk } from "redux/questions/thunks/getQuestions.thunk";
import { createProductThunk } from "redux/products/thunks/createProduct.thunk";
import { uploadAttachmentThunk } from "redux/templates/thunks/uploadAttachment.thunk";
import { productsActions } from "redux/products/products.slice";
import { getSettingsThunk } from "redux/settings/thunks/getSettings.thunk";
import Loading from "components/Loading";

const Questions: React.FC = () => {
  const [progress, setProgress] = useState<number>(0);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const questions = useSelector<RootState, IQuestion[]>(state => state.questions.allQuestions);
  const loadingQuestions = useSelector<RootState, boolean>(state => state.questions.loadingQuestions);
  const selectedType = useSelector<RootState, string>(state => state.products.selectedType!);
  const selectedCover = useSelector<RootState, ITemplate<IElement>>(state => state.products.updatedCover!);
  const coverType = useSelector<RootState, CoverType>(state => state.products.coverType!);
  const savedAnswers = useSelector<RootState, Answer[]>(state => state.products.answers);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const questionsRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation(["questions", "navigation", "errors"]);

  const requiredQuestions = useMemo(() => questions.filter(question => question.required), [questions]);

  const handleNextClick = useCallback(async () => {
    try {
      setLoading(true);
      const hasAllRequiredQuestions = answers.length > 0 && requiredQuestions.every(question => Boolean(answers.find(answer => answer.questionId === question.id)));

      if (!hasAllRequiredQuestions) {
        setError("Not all required questions have been answered!");
        return;
      }

      const newAnswers: Answer[] = await Promise.all(answers.map(async answer => {
        if (Array.isArray(answer.answer) && answer.answer[0] && answer.answer[0] instanceof File) {
          try {
            const files = [];
            const attachmentPaths = [];

            for (const file of answer.answer) {
              const attachment = await dispatch(uploadAttachmentThunk({ file: file as File })).unwrap();

              files.push(attachment.id);
              attachmentPaths.push(attachment.attachmentPath);
            }

            return {
              ...answer,
              answer: files,
              attachmentPath: attachmentPaths
            };
          } catch(err) {
            console.log(err);
          }
        } else if (answer.answer instanceof File) {
          try {
            const attachment = await dispatch(uploadAttachmentThunk({ file: answer.answer })).unwrap();

            return {
              ...answer,
              answer: [attachment.id],
              attachmentPath: [attachment.attachmentPath]
            };
          } catch(err) {
            console.log(err);
          }
        } else if (typeof answer.answer === "string") {
          return {
            ...answer,
            answer: [answer.answer]
          };
        }
        return answer;
      }));

      const payload: ICreateProductPayload = {
        answers: newAnswers,
        cover: selectedCover,
        cover_type: coverType,
        book_slug: selectedType
      };

      const res = await dispatch(createProductThunk(payload));

      if (isFulfilled(createProductThunk)(res)) {
        dispatch(productsActions.saveAnswers(newAnswers));
        navigate(paths.pages);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [answers]);

  const handleAnswerChange = useCallback((questionId: Answer["questionId"], value: Answer["answer"] | null) => {
    setError("");
    if (value && !answers.find(answer => answer.questionId === questionId)) {
      setAnswers(prevAnswers => [...prevAnswers, { questionId, answer: value }]);
      return;
    }
    if (!value || (Array.isArray(value) && value.length === 0)) {
      setAnswers(prevAnswers => prevAnswers.filter(answer => answer.questionId !== questionId));
      return;
    }
    if (answers.find(answer => answer.questionId === questionId)) {
      setAnswers(prevAnswers => prevAnswers.map(answer => answer.questionId === questionId ? { ...answer, answer: value } : answer));
      return;
    }
  }, [answers]);

  useEffect(() => {
    dispatch(productsActions.saveAnswers(answers));
    const answeredRequiredQuestions = requiredQuestions.filter(question => Boolean(answers.find(answer => answer.questionId === question.id)));
    setProgress(Number(map(answeredRequiredQuestions.length, 0, requiredQuestions.length, 0 ,1).toFixed(2)));
  }, [answers, requiredQuestions]);

  useEffect(() => {
    if (savedAnswers) {
      setAnswers(savedAnswers);
    }

    dispatch(getSettingsThunk());
    dispatch(getQuestionsThunk(selectedType));
  }, []);

  useEffect(() => {
    if (!selectedType) {
      navigate(paths.chooseBookType);
      return;
    }

    if (!selectedCover) {
      navigate(paths.personalizeCover);
      return;
    }
  }, [selectedType, selectedCover]);

  return (
    <div className={classes.container}>
      <Navbar currentStep={2} stepInfo={t("stepInfo")} />
      <div className={classes.main}>
        <div ref={questionsRef} className={classes.questions}>
          {loadingQuestions
            ? <Spinner style={{ alignItems: "center", justifyItems: "center", display: "grid", width: "100%", flex: 1 }} />
            : questions.map((question, i) => (
              <div key={question.id} className={classes.question}>
                <label className={classes.label} htmlFor={question.id}>{!!answers.find(answer => answer.questionId === question.id) && <DoneSvg />} {i + 1}. {question.label} {question.required ? <span className={classes.required}>*</span> : null}</label>
                {question.description && (
                  <p className={classes.description}>{question.description}</p>
                )}
                <InputRenderer id={question.id} question={question} type={question.questionType} value={answers.find(answer => answer.questionId === question.id)?.answer} onChange={handleAnswerChange} />
              </div>
            ))}
        </div>
      </div>
      <div className={classes.bottom} >
        {error && <span className={classes.error}>{error}</span>}
        {!loadingQuestions && <ProgressBar progress={progress} />}
        <div className={classes.nextButton}>
          <Button label={t("navigation:back")} secondary onClick={() => navigate(paths.personalizeCover)} />
          <Button disabled={progress !== 1} label={t("navigation:next")} onClick={handleNextClick} />
        </div>
      </div>
      {loading && <Loading title={t("loading")} />}
    </div>
  );
};

export default Questions;
