export const fontFamilyOptions: Array<any> = [
  { label: "Nickainley", value: "Nickainley" },
  { label: "Peace Sans", value: "Peace Sans" },
  { label: "Sensei", value: "Sensei" },
  { label: "Amatic SC", value: "Amatic SC" },
  { label: "Alice", value: "Alice" },
  { label: "VollkornSC", value: "VollkornSC" },
  { label: "Oswald", value: "Oswald" },
  { label: "Andika", value: "Andika" },
  { label: "Pattaya", value: "Pattaya" },
];
