import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { ISettingsState } from "models";
import { getSettingsThunk } from "./thunks/getSettings.thunk";

export const settingsExtraReducers = (
  builder: ActionReducerMapBuilder<ISettingsState>
): ActionReducerMapBuilder<ISettingsState> => builder
  .addCase(getSettingsThunk.fulfilled, (state, action) => {
    state.deliveryDay = action.payload.delivery_day;
    state.numberOfDaysAfterDelivery = action.payload.number_of_days_after_delivery;
  });
