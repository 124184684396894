import { createAsyncThunk } from "@reduxjs/toolkit";

import attachmentsApi from "api/attachmentsApi";
import { prefix } from "./config";
import { IAttachment, Paginated } from "shared/lib/models";

export const getAttachmentsThunk = createAsyncThunk<Paginated<IAttachment>, undefined>(`${prefix} Get Attachments`, async (_, { rejectWithValue }) => {
  try {
    return await attachmentsApi.getAttachments();
  } catch (err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
