import React from "react";
import { isMobileOnly } from "react-device-detect";
import { unstable_HistoryRouter as HistoryRouter, Routes as RRRoutes, Route, Navigate } from "react-router-dom";
import { createBrowserHistory } from "history";

import paths from "config/paths";
import ChooseType from "./ChooseType";
import PersonalizeCover from "./PersonalizeCover";
import Questions from "./Questions";
import Pages from "./Pages";
import MobileEditPage from "./EditPage/Mobile";
import EditPage from "./EditPage";
import { ScrollToTop } from "ui";

const history = createBrowserHistory();

const Routes: React.FC = () => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <HistoryRouter history={history}>
      <ScrollToTop />
      <RRRoutes>
        <Route element={<ChooseType />} path={paths.chooseBookType} />
        <Route element={<PersonalizeCover />} path={paths.personalizeCover} />
        <Route element={<Questions />} path={paths.questions} />
        <Route element={<Pages />} path={paths.pages} />
        <Route element={isMobileOnly ? <MobileEditPage /> : <EditPage />} path={paths.editPage} />
        {/*<Route element={<Finalize />} path={paths.finalize} />*/}
        <Route element={<Navigate to={paths.chooseBookType} />} path="*" />
      </RRRoutes>
    </HistoryRouter>
  );
};

export default Routes;
