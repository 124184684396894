import React from "react";
import classes from "./Item.module.scss";

export interface IItemProps {
  groupName?: string
  id: string
  value: string | number
  label: string
  checked?: boolean
  onChange: (value: string | number) => void
}

const Item: React.FC<IItemProps> = ({ groupName, checked, id, value, label, onChange: pushChange }) => {
  return (
    <div className={classes.groupItem}>
      <input
        checked={checked}
        id={id}
        name={groupName}
        type="radio"
        value={value}
        onChange={e => {
          pushChange(e.target.value);
        }}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default Item;
