import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";
import { PersistConfig, persistReducer } from "redux-persist";

import { productsSlice } from "./products/products.slice";
import { questionsSlice } from "./questions/questions.slice";
import { templatesSlice } from "./templates/templates.slice";
import { attachmentsSlice } from "./attachments/attachments.slice";
import { settingsSlice } from "./settings/settings.slice";
import { IProductsState } from "models";

const productsPersistConfig: PersistConfig<IProductsState> = {
  key: "products",
  storage: storage,
  blacklist: ["allProducts", "loadingProducts"]
};

export default combineReducers({
  products: persistReducer(productsPersistConfig, productsSlice.reducer),
  questions: questionsSlice.reducer,
  templates: templatesSlice.reducer,
  attachments: attachmentsSlice.reducer,
  settings: settingsSlice.reducer
});
