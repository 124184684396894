import React from "react";

import { IAnswer } from "shared/lib/models";
import Checkbox from "../Checkbox";
import classes from "./Checkboxes.module.scss";

type CheckboxesProps = {
  options: IAnswer[]
  onChange: (value: string[]) => void
  value: string[]
}

const Checkboxes: React.FC<CheckboxesProps> = ({ options, value, onChange: pushChange }) => {
  const handleOnChange = (option: IAnswer, checked: boolean) => {
    if (checked) {
      pushChange([...value, option.id]);
    } else {
      pushChange(value.filter(val => val !== option.id));
    }
  };

  return (
    <div className={classes.container}>
      {options.map(option => (
        <Checkbox key={option.id} checked={value.indexOf(option.id) > -1} id={option.id} label={option.answer} onChange={checked => handleOnChange(option, checked)} />
      ))}
    </div>
  );
};

export default Checkboxes;
