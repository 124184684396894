export default {
  products: {
    get: "/books",
    post: "/products",
    put: "/products/{productId}",
    order: "/products/order/{productId}"
  },
  questions: {
    get: "/questions"
  },
  templates: {
    get: "/templates"
  },
  attachments: {
    get: "/attachments",
    post: "/attachments"
  },
  settings: {
    put: "/settings",
    get: "/settings"
  }
};
