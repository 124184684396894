import React from "react";
import { useTranslation } from "react-i18next";

import classes from "./StepCounter.module.scss";

type StepCounterProps = {
  currentStep: number;
  stepInfo: string;
}

const StepCounter: React.FC<StepCounterProps> = ({ currentStep, stepInfo }) => {
  const { t } = useTranslation(["stepCounter"]);
  
  return (
    <div className={classes.container}>
      {t("step", { currentStep })}: {stepInfo}
    </div>
  );
};

export default StepCounter;
