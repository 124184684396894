import { createAsyncThunk } from "@reduxjs/toolkit";

import productsApi from "api/productsApi";
import { prefix } from "./config";
import { IUpdateProductPayload, IProduct } from "shared/lib/models";

export const updateProductThunk = createAsyncThunk<IProduct, IUpdateProductPayload>(`${prefix} Update Product`, async (payload, { rejectWithValue }) => {
  try {
    return await productsApi.updateProduct(payload);
  } catch (err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
