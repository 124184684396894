import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { isFulfilled } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import classes from "./EditPage.module.scss";
import paths from "config/paths";
import Navbar from "components/Navbar";
import Button from "components/Button";
import Loading from "components/Loading";
import Select, { Option } from "components/Select";
import TemplatePersonalization, { TemplatePersonalizationRef } from "components/TemplatePersonalization";
import { RootState } from "store";
import { fontFamilyOptions } from "shared/lib/utils";
import { useAppDispatch } from "hooks";
import { baseURL } from "config/constants";
import { ComponentTypes, IProduct, ITemplate } from "shared/lib/models";
import { ReactComponent as UndoSvg } from "assets/images/undo.svg";
import { ReactComponent as RedoSvg } from "assets/images/redo.svg";
import { ReactComponent as ArrowUpSvg } from "assets/images/arrow_up.svg";
import { ReactComponent as ArrowDownSvg } from "assets/images/arrow_down.svg";
import { getTemplatesThunk } from "redux/templates/thunks/getTemplates.thunk";
import { updateProductThunk } from "redux/products/thunks/updateProduct.thunk";
import { IElement } from "models";

const EditPage: React.FC = () => {
  const { pageId } = useParams();

  const finalProduct = useSelector<RootState, IProduct>((state) => state.products.finalProduct!);
  const defaultTemplate = useSelector<RootState, ITemplate<IElement>>(state => state.products.finalProduct!.pages.find(page => page.page_id === pageId)!);
  const templates = useSelector<RootState, ITemplate<IElement>[]>(state => state.templates.coverTemplatesForBook);

  const [editingComponent, setEditingComponent] = useState<IElement | null>(null);
  const [templatesOpen, setTemplatesOpen] = useState(false);
  const [fontFamily, setFontFamily] = useState<Option<string> | null>(null);
  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);

  const personalizationRef = useRef<TemplatePersonalizationRef>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["editPage", "navigation", "errors"]);

  const pagesIds = useMemo(() => finalProduct.pages.map(page => page.id), [finalProduct]);

  const toggleTemplates = useCallback(() => {
    setTemplatesOpen(prevValue => !prevValue);
  }, []);

  const handleFontFamilyChange = useCallback((value: Option<string> | null) => {
    if (!(editingComponent && editingComponent.element_type.toUpperCase() === ComponentTypes.TEXT)) {
      setError(t("noComponentSelectedOfTypeText"));
      return;
    }
    personalizationRef.current?.resetTemplate((previousTemplate) => ({
      ...previousTemplate,
      elements: previousTemplate.elements.map(element => {
        if (element.element_properties.id === editingComponent.element_properties.id) {
          return {
            ...element,
            element_properties: {
              ...element.element_properties,
              fontFamily: value?.value
            }
          };
        }

        return element;
      })
    }));
    setFontFamily(value);
  }, [editingComponent, t]);

  const handleComponentClick = useCallback((component: IElement) => {
    if (component.element_type.toUpperCase() === ComponentTypes.TEXT) {
      setFontFamily(fontFamilyOptions.find(option => option.value === component.element_properties.fontFamily) ?? null);
    }
    setError(null);
    setEditingComponent(component);
  }, []);

  const handlePageSave = useCallback(async () => {
    setLoading(true);

    const updatedTemplate = JSON.parse(JSON.stringify(personalizationRef.current!.getTemplate()));

    await personalizationRef.current?.resetTemplate(updatedTemplate);

    const res = await dispatch(updateProductThunk({ template: { ...updatedTemplate, page_id: pageId! }, productId: finalProduct.id }));

    setLoading(false);
    if (isFulfilled(updateProductThunk)(res)) {
      navigate(paths.pages);
    } else {
      setError(t("somethingWentWrong"));
    }
  }, [dispatch, finalProduct.id, navigate, pageId, t]);

  useEffect(() => {
    if (!finalProduct) {
      navigate(paths.questions);
    }
  }, [finalProduct]);

  useEffect(() => {
    dispatch(getTemplatesThunk({ showAll: true, template_type: "page" }));
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Navbar
        backTo={paths.pages}
        right={<div className={classes.navRight}>
          <div className={classes.options}>
            <UndoSvg onClick={personalizationRef.current?.undoTemplate} />
            <RedoSvg onClick={personalizationRef.current?.redoTemplate} />
          </div>
          <Button label={t("navigation:save")} onClick={handlePageSave} />
        </div>}
      />
      <div className={classes.wrapper}>
        <div className={classes.options}>
          {error && <div className={classes.error}>{error}</div>}
          <Select isClearable={false} label={t("fontFamily")} options={fontFamilyOptions} value={fontFamily} onChange={value => handleFontFamilyChange(value)} />
          <div className={classes.templatesWrapper}>
            <div className={classes.input} onClick={toggleTemplates}>
              <span>{t("background")}</span>
              <div className={classes.actions}>
                {templatesOpen ? <ArrowUpSvg /> : <ArrowDownSvg />}
              </div>
            </div>
            <div className={`${classes.templates} ${templatesOpen ? classes.open : null}`}>
              {templates.filter(template => !(pagesIds.indexOf(template.id) > -1)).map(template => <img key={template.id} alt={template.name} src={`${template.preview_image.indexOf("http") > -1 ? "" : baseURL}${template.preview_image}`} width="100%" onClick={() => personalizationRef.current?.resetTemplate(template)} />)}
            </div>
          </div>
        </div>
        <div className={classes.personalization}>
          <div className={classes.page}>
            <TemplatePersonalization key={defaultTemplate.id} ref={personalizationRef} preview={loading} product={finalProduct} template={defaultTemplate} onComponentClick={handleComponentClick} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPage;
