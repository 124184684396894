import { createSlice } from "@reduxjs/toolkit";

import { questionsInitialState } from "./questions.initialState";
import { questionsExtraReducers } from "./questions.extraReducers";

export const questionsSlice = createSlice({
  name: "questions",
  initialState: questionsInitialState,
  reducers: {},
  extraReducers: questionsExtraReducers
});

export const { actions: questionsActions } = questionsSlice;
