import axiosInstance from "config/axiosInstance";
import paths from "api/paths";
import { ICreateProductPayload, IProduct, IPaginatedBook, IUpdateProductPayload, IOrderProductPayload } from "shared/lib/models";

export default {
  getProducts: async (): Promise<IPaginatedBook> => {
    return await axiosInstance.get<IPaginatedBook, IPaginatedBook>(paths.products.get);
  },
  createProduct: async (payload: ICreateProductPayload): Promise<IProduct> => {
    return await axiosInstance.post<IProduct, IProduct>(paths.products.post, payload);
  },
  updateProduct: async (payload: IUpdateProductPayload): Promise<IProduct> => {
    return await axiosInstance.put<IProduct, IProduct>(paths.products.put.replace("{productId}", payload.productId), payload);
  },
  orderProduct: async (payload: IOrderProductPayload): Promise<undefined> => {
    return await axiosInstance.post<undefined, undefined>(paths.products.order.replace("{productId}", payload.productId));
  }
};
