import { createAsyncThunk } from "@reduxjs/toolkit";

import productsApi from "api/productsApi";
import { prefix } from "./config";
import { IPaginatedBook } from "shared/lib/models";

export const getProductsThunk = createAsyncThunk<IPaginatedBook, undefined>(`${prefix} Get Products`, async (_, { rejectWithValue }) => {
  try {
    return await productsApi.getProducts();
  } catch (err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
