import React, { useMemo } from "react";

import classes from "./ProgressBar.module.scss";

type ProgressBarProps = {
  progress: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  const progressValue = useMemo(() => `${progress ? (progress * 100).toFixed(0) : 0}%`, [progress]);
  
  return (
    <div className={classes.container}>
      <div className={classes.percentage}>{progressValue}</div>
      <div className={classes.progressContainer}>
        <div className={classes.progress} style={{ width: progressValue }} />
      </div>
    </div>
  );
};

export default ProgressBar;
