import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isFulfilled } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SwipeableDrawer } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";

import classes from "./Mobile.module.scss";
import Navbar from "components/Navbar";
import Button from "components/Button";
import paths from "config/paths";
import Loading from "components/Loading";
import TemplatePersonalization, { TemplatePersonalizationRef } from "components/TemplatePersonalization";
import { ReactComponent as FontSvg } from "assets/images/font.svg";
import { ReactComponent as RedoSvg } from "assets/images/redo.svg";
import { ReactComponent as UndoSvg } from "assets/images/undo.svg";
import { ReactComponent as BackgroundSvg } from "assets/images/background.svg";
import { RootState } from "store";
import { useAppDispatch } from "hooks";
import { fontFamilyOptions } from "shared/lib/utils";
import { baseURL } from "config/constants";
import { Option } from "components/Select";
import { ComponentTypes, IProduct, ITemplate } from "shared/lib/models";
import { updateProductThunk } from "redux/products/thunks/updateProduct.thunk";
import { IElement } from "models";
import { getTemplatesThunk } from "redux/templates/thunks/getTemplates.thunk";

const MobileEditPage: React.FC = () => {
  const { pageId } = useParams();

  const finalProduct = useSelector<RootState, IProduct>(state => state.products.finalProduct!);
  const template = useSelector<RootState, ITemplate<IElement>>(state => state.products.finalProduct!.pages.find(page => page.page_id === pageId)!);
  const templates = useSelector<RootState, ITemplate<IElement>[]>(state => state.templates.coverTemplatesForBook);

  const [editingComponent, setEditingComponent] = useState<IElement | null>(null);
  const [openFontFamily, setOpenFontFamily] = useState(false);
  const [openBackground, setOpenBackground] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const personalizationRef = useRef<TemplatePersonalizationRef>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["editPage", "navigation"]);

  const pagesIds = useMemo(() => finalProduct.pages.map(page => page.id), [finalProduct]);

  const handleComponentClick = useCallback((component: IElement) => {
    setError(null);
    setEditingComponent(component);
  }, []);

  const handleFontFamilyChange = useCallback((option: Option<string>) => {
    personalizationRef.current?.resetTemplate((previousTemplate) => ({
      ...previousTemplate,
      elements: previousTemplate.elements.map(element => {
        if (element.id === editingComponent!.id) {
          return {
            ...element,
            element_properties: {
              ...element.element_properties,
              fontFamily: option?.value
            }
          };
        }

        return element;
      })
    }));
    setOpenFontFamily(false);
  }, [editingComponent]);

  const handlePageSave = useCallback(async () => {
    setLoading(true);

    const updatedTemplate = JSON.parse(JSON.stringify(personalizationRef.current!.getTemplate()));

    await personalizationRef.current?.resetTemplate(updatedTemplate);

    const res = await dispatch(updateProductThunk({ template: { ...updatedTemplate, page_id: pageId }, productId: finalProduct.id }));

    setLoading(false);
    if (isFulfilled(updateProductThunk)(res)) {
      navigate(paths.pages);
    } else {
      setError(t("somethingWentWrong"));
    }
  }, [dispatch, finalProduct.id, navigate, pageId, t]);

  useEffect(() => {
    if (!finalProduct) {
      navigate(paths.questions);
    }
  }, [finalProduct]);

  useEffect(() => {
    dispatch(getTemplatesThunk({ showAll: true, template_type: "page" }));
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Navbar />
      <div className={classes.container}>
        <div className={classes.personalization}>
          <TemplatePersonalization key={template.id} ref={personalizationRef} preview={loading} product={finalProduct} template={template} onComponentClick={handleComponentClick} />
        </div>
        {error && <div className={classes.error}>{error}</div>}
        <div className={classes.actions}>
          <div>
            <FontSvg
              height={42}
              width={42}
              onClick={() => {
                if (editingComponent && editingComponent.element_type.toUpperCase() === ComponentTypes.TEXT)
                  setOpenFontFamily(true);
                else setError(t("noComponentSelectedOfTypeText"));
              }}
            />
          </div>
          <div>
            <UndoSvg height={42} width={42} onClick={personalizationRef.current?.undoTemplate} />
          </div>
          <div>
            <RedoSvg height={42} width={42} onClick={personalizationRef.current?.redoTemplate} />
          </div>
          <div>
            <BackgroundSvg height={42} width={42} onClick={() => setOpenBackground(true)} />
          </div>
        </div>
        <div className={classes.saveButton}>
          <Button label={t("navigation:back")} secondary onClick={() => navigate(paths.pages)} />
          <Button label={t("navigation:save")} onClick={handlePageSave} />
        </div>
      </div>
      <SwipeableDrawer anchor="bottom" BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.3)" } }} open={openFontFamily} onClose={() => setOpenFontFamily(false)} onOpen={() => setOpenBackground(true)}>
        <div className={classes.drawer}>
          {fontFamilyOptions.map((option) => (
            <div
              key={option.value}
              style={{ fontFamily: option.value }}
              onClick={() => handleFontFamilyChange(option)}
            >{option.label}</div>
          ))}
        </div>
      </SwipeableDrawer>

      <SwipeableDrawer anchor="bottom" BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.3)" } }} open={openBackground} onClose={() => setOpenBackground(false)} onOpen={() => setOpenBackground(true)}>
        <div className={classes.drawer}>
          <div className={classes.templates}>
            {templates.filter(template => !(pagesIds.indexOf(template.id) > -1)).map(template => <img key={template.id} alt={template.name} src={`${template.preview_image.indexOf("http") > -1 ? "" : baseURL}${template.preview_image}`} width="100%" onClick={() => {personalizationRef.current?.resetTemplate(template); setOpenBackground(false);}} />)}
          </div>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default MobileEditPage;