import { createSlice } from "@reduxjs/toolkit";

import { attachmentsInitialState } from "./attachments.initialState";
import { attachmentsExtraReducers } from "./attachments.extraReducers";

export const attachmentsSlice = createSlice({
  name: "attachments",
  initialState: attachmentsInitialState,
  reducers: {},
  extraReducers: attachmentsExtraReducers,
});

export const { actions: attachmentsActions } = attachmentsSlice;
