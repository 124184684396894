import { IProductsReducers } from "models";

export const productsReducers: IProductsReducers = {
  setType: (state, action) => {
    state.selectedType = action.payload;
  },
  setCover: (state, action) => {
    state.selectedCover = action.payload;
    state.updatedCover = action.payload;
  },
  setUpdatedCover: (state, action) => {
    state.updatedCover = action.payload;
  },
  setCoverType: (state, action) => {
    state.coverType = action.payload;
  },
  updateFinalProduct: (state, action) => {
    state.finalProduct = action.payload;
  },
  saveAnswers: (state, action) => {
    state.answers = action.payload;
  },
  clearAnswers: (state) => {
    state.answers = [];
  }
};
