import paths from "./paths";
import axiosInstance from "config/axiosInstance";
import { IElement } from "models";
import { IAttachment, IGetTemplatesPayload, ITemplate, IUploadAttachmentPayload, Paginated } from "shared/lib/models";

export default {
  getTemplates: async (payload: IGetTemplatesPayload): Promise<Paginated<ITemplate<IElement>>> => {
    return await axiosInstance.get<Paginated<ITemplate<IElement>>, Paginated<ITemplate<IElement>>>(paths.templates.get, {
      params: payload
    });
  },
  uploadAttachment: async (payload: IUploadAttachmentPayload): Promise<IAttachment> => {
    const formData = new FormData();
    formData.append("image", payload.file);
    return await axiosInstance.post<IAttachment, IAttachment>(paths.attachments.post, formData);
  },
};
