import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import paths from "config/paths";
import Navbar from "components/Navbar";
import Button from "components/Button";
import Loading from "components/Loading";
import Select, { Option } from "components/Select";
import classes from "./PersonalizeCover.module.scss";
import TemplatePersonalization, { TemplatePersonalizationRef } from "components/TemplatePersonalization";
import { RootState } from "store";
import { coverTypes } from "./utils";
import { useAppDispatch } from "hooks";
import { ComponentTypes, CoverType, ITemplate } from "shared/lib/models";
import { productsActions } from "redux/products/products.slice";
import { getTemplatesThunk } from "redux/templates/thunks/getTemplates.thunk";
import { ReactComponent as EditSvg } from "assets/images/edit.svg";
import { IElement } from "models";

const PersonalizeCover: React.FC = () => {
  const stateCoverType = useSelector<RootState, string | null>(state => state.products.coverType);

  const [error, setError] = useState<string | null>(null);
  const [coverType, setCoverType] = useState<Option<CoverType> | null>(stateCoverType ? coverTypes.find(coverType => coverType.value === stateCoverType)! : null);

  const selectedCover = useSelector<RootState, ITemplate<IElement> | null>(state => state.products.selectedCover);
  const updatedCover = useSelector<RootState, ITemplate<IElement> | null>(state => state.products.updatedCover);
  const covers = useSelector<RootState, ITemplate<IElement>[]>(state => state.templates.coverTemplatesForBook);
  const coversLoading = useSelector<RootState, boolean>(state => state.templates.templatesLoading);
  const bookSlug = useSelector<RootState, string | null>(state => state.products.selectedType);

  const templatePersonalizationRef = useRef<TemplatePersonalizationRef | null>(null);

  const navigate = useNavigate();
  const params = useParams<{ book_slug: string }>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["chooseCover", "navigation", "errors"]);

  const handleCoverTypeChange = useCallback(async (value: Option<CoverType> | null) => {
    setCoverType(value);
    if (value) await dispatch(productsActions.setCoverType(value.value));
  }, []);

  const handleNextButtonClick = useCallback(async () => {
    if (!coverType?.value ) {
      setError(t("errors:noCoverTypeSelected"));
      return;
    }

    const currentTemplate = templatePersonalizationRef.current?.getTemplate();

    if (!currentTemplate) return;

    for (const element of currentTemplate.elements) {
      const coverElement = selectedCover?.elements.find(el => el.element_properties.id === element.element_properties.id);
      if (
        coverElement &&
        element.element_type.toUpperCase() === ComponentTypes.TEXT &&
        element.element_properties.required === "true" &&
        (!element.element_properties.value || element.element_properties.value === coverElement.element_properties.placeholder)
      ) {
        setError(t("errors:changeAllRequiredTexts"));
        return;
      }
    }

    await dispatch(productsActions.setUpdatedCover(currentTemplate));
    navigate(paths.questions);
  }, [coverType]);

  useEffect(() => {
    if (!bookSlug && !params.book_slug) {
      navigate(paths.chooseBookType);
      return;
    }
  }, [bookSlug, params.book_slug]);

  useEffect(() => {
    dispatch(getTemplatesThunk({ book_slug: bookSlug || params.book_slug, template_type: "cover" }));
  }, []);

  return (
    <div className={classes.container}>
      <Navbar backTo={paths.chooseBookType} currentStep={1} stepInfo={t("stepInfo")} />
      <div className={classes.main}>
        {coversLoading && <Loading title={t("loading")} />}
        <div className={classes.covers}>
          {covers.map(cover => (
            <div key={cover.id} className={classes.page} onClick={() => dispatch(productsActions.setCover(cover))} >
              <img
                alt={cover.name}
                src={cover.preview_image}
              />
              <EditSvg className={classes.editImage} height={50} width={50} />
            </div>
          ))}
        </div>
        <div className={classes.personalization}>
          <div className={classes.cover}>
            {updatedCover && <TemplatePersonalization key={updatedCover.id} ref={templatePersonalizationRef} template={updatedCover} />}
          </div>
        </div>
        <div className={classes.options}>
          <Select<CoverType> isClearable={false} label={t("coverType")} options={coverTypes} value={coverType} isMenuAlwaysOpen onChange={handleCoverTypeChange} />
          {error && <div className={classes.error}>{error}</div>}
          <div className={classes.nextButton}>
            <Button
              label={t("navigation:next")}
              onClick={handleNextButtonClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};


export default PersonalizeCover;
