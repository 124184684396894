import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { ITemplatesState } from "models";
import { getTemplatesThunk } from "./thunks/getTemplates.thunk";

export const templatesExtraReducers = (
  builder: ActionReducerMapBuilder<ITemplatesState>
): ActionReducerMapBuilder<ITemplatesState> =>
  builder
    .addCase(getTemplatesThunk.fulfilled, (state, action) => {
      state.coverTemplatesForBook = action.payload.data;
      state.templatesLoading = false;
    })
    .addCase(getTemplatesThunk.pending, state => {
      state.templatesLoading = true;
    })
    .addCase(getTemplatesThunk.rejected, state => {
      state.templatesLoading = false;
    });
