import React, { forwardRef } from "react";
import { useSelector } from "react-redux";

import { baseURL } from "config/constants";
import { Answer, CoverType } from "shared/lib/models";
import { RootState } from "store";

import UITemplatePersonalization, { TemplatePersonalizationProps as UITemplatePersonalizationProps } from "ui/components/TemplatePersonalization";

type TemplatePersonalizationProps = Omit<UITemplatePersonalizationProps, "answers" | "coverType" | "baseURL">

const TemplatePersonalization: React.FC<TemplatePersonalizationProps> = forwardRef(function TemplatePersonalization (props, ref) {
  const answers = useSelector<RootState, Answer[]>(state => state.products.answers);
  const coverType = useSelector<RootState, CoverType | null>(state => state.products.coverType);

  return <UITemplatePersonalization {...props} ref={ref} answers={answers} baseURL={baseURL!} coverType={coverType} />;
});

export type { TemplatePersonalizationRef } from "ui/components";

export default TemplatePersonalization;