import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "../Button";
import classes from "./Navbar.module.scss";
import StepCounter from "components/StepCounter";
import { useTranslation } from "react-i18next";

interface NavbarProps {
  backTo?: string;
  currentStep?: number;
  stepInfo?: string;
  right?: React.ReactNode
}

const Navbar: React.FC<NavbarProps> = ({ currentStep, stepInfo, right, backTo }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["navigation"]);

  return (
    <div className={classes.navbar}>
      <div className={classes.navigation}>
        {backTo && <Button label={t("back")} secondary onClick={() => navigate(backTo)} />}
        {right && (
          <div>
            {right}
          </div>
        )}
      </div>
      {currentStep && stepInfo && <StepCounter currentStep={currentStep} stepInfo={stepInfo} />}
    </div>
  );
};

export default Navbar;
