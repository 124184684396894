import React, { CSSProperties } from "react";

import classes from "./Spinner.module.scss";

type SpinnerProps = {
  style?: CSSProperties
}

const Spinner: React.FC<SpinnerProps> = ({ style }) => {
  return (
    <div className={classes.spinner} style={style}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;
