import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import classes from "./ChooseType.module.scss";
import Button from "components/Button";
import paths from "config/paths";
import Loading from "components/Loading";
import { RootState } from "store";
import { useAppDispatch } from "hooks";
import { IBook } from "shared/lib/models";
import { productsActions } from "redux/products/products.slice";
import { getProductsThunk } from "redux/products/thunks/getProducts.thunk";

const ChooseType: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const products = useSelector<RootState, IBook[]>(state => state.products.allProducts);
  const loadingProducts = useSelector<RootState, boolean>(state => state.products.loadingProducts);

  useEffect(() => {
    dispatch(productsActions.setCover(null));
    dispatch(getProductsThunk());
  }, []);

  return (
    <div className={classes.main}>
      {loadingProducts && <Loading />}
      <div className={classes.products}>
        {products.map(product => (
          <div key={product.id} className={classes.product}>
            <div className={classes.wrapper}>
              <img alt={product.bookSlug} src={product.bookImage} />
              <h1>{product.bookName}</h1>
              <ul>
                <li>40 pages</li>
                <li>High quality</li>
                <li>3 days delivery</li>
                <li>Personalised cover</li>
              </ul>
              <div className={classes.orderBtn}>
                <Button
                  label="Order"
                  big
                  onClick={() => {
                    dispatch(productsActions.setType(product.bookSlug));
                    navigate(paths.personalizeCover);
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChooseType;
