import { createAsyncThunk } from "@reduxjs/toolkit";

import productsApi from "api/productsApi";
import { prefix } from "./config";
import { IOrderProductPayload } from "shared/lib/models";

export const orderProductThunk = createAsyncThunk<undefined, IOrderProductPayload>(`${prefix} Get Products`, async (payload, { rejectWithValue }) => {
  try {
    return await productsApi.orderProduct(payload);
  } catch (err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
