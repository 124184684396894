import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { IAttachmentsState } from "models";
import { getAttachmentsThunk } from "./thunks/getAttachments.thunk";

export const attachmentsExtraReducers = (
  builder: ActionReducerMapBuilder<IAttachmentsState>
): ActionReducerMapBuilder<IAttachmentsState> =>
  builder
    .addCase(getAttachmentsThunk.fulfilled, (state, action) => {
      state.attachments = action.payload.data;
    });
