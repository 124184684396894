import React from "react";

import classes from "./Textarea.module.scss";

interface TextareaProps {
  id?: string
  name?: string
  cols?: number;
  rows?: number;
  placeholder?: string
  success?: boolean
  error?: boolean
  maxLength?: number
  onChange: (text: string) => void
  value: string
}

const Textarea: React.FC<TextareaProps> = ({ name, placeholder, id, maxLength, cols, rows, onChange, value, success, error }) => {
  return <textarea className={`${classes.textarea} ${success ? classes.success : ""} ${error ? classes.error : ""}`} cols={cols} id={id} maxLength={maxLength} name={name} placeholder={placeholder} rows={rows} value={value} onChange={e => onChange(e.target.value)} />;
};

export default Textarea;
