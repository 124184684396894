import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import * as locales from "locales";

const languageDetector = new LanguageDetector(null, {
  lookupQuerystring: "language"
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "bg_BG",
    resources: locales,
  });

export default i18n;
