import React from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { DatePicker } from "@material-ui/pickers";
import { DropzoneArea } from "material-ui-dropzone";

import Textarea from "../Textarea";
import TextInput from "../TextInput";
import Checkboxes from "./Checkboxes";
import Select from "../Select";
import Group from "../Radio/Group";
import Item from "../Radio/Item";
import { Answer, IAnswer, IQuestion, QuestionType } from "shared/lib/models";
import { ISettingsState } from "models";
import { RootState } from "store";

interface InputRendererProps {
  id: string
  type: QuestionType
  value: Answer["answer"] | undefined
  onChange: (questionId: Answer["questionId"], value: Answer["answer"]) => void
  question: IQuestion
}

const InputRenderer: React.FC<InputRendererProps> = ({ type, question, id, value, onChange: pushChange }) => {
  const settings = useSelector<RootState, ISettingsState>(state => state.settings);

  switch (type) {
  case QuestionType.FILE:
    return (
      <DropzoneArea
        acceptedFiles={["image/*"]}
        alertSnackbarProps={{ autoHideDuration: 2000 }}
        filesLimit={10}
        maxFileSize={50 * 1024 * 1024}
        previewGridProps={{ container: { spacing: 1, direction: "row", sm: 4 }, item: {} }}
        previewText="Selected files"
        showPreviewsInDropzone={false}
        clearOnUnmount
        showPreviews
        onChange={files => pushChange(id, files)}
      />
    );
  case QuestionType.TEXTAREA:
    return <Textarea id={id} maxLength={500} name={id} placeholder="Text" rows={3} success={!!value} value={value as string || ""} onChange={value => pushChange(id, value)} />;
  case QuestionType.TEXT:
    return <TextInput id={id} placeholder="Text" success={!!value} value={value as string || ""} onChange={e => pushChange(id, e.target.value)} />;
  case QuestionType.DATE:
    return <DatePicker placeholder="Select date..." value={value || null} disableToolbar onChange={value => pushChange(id, value?.toISOString() || "")} />;
  case QuestionType.EVENT_DATE: {
    const weekdaysMap = dayjs.weekdays(true).map((value, idx) => ({ value, idx }));
    let nextOrderDay = dayjs().weekday(weekdaysMap.find(weekday => weekday.value === settings.deliveryDay)!.idx);
    if (dayjs().startOf("day") >= nextOrderDay.startOf("day")) {
      nextOrderDay = nextOrderDay.add(7, "day");
    }
    return <DatePicker minDate={nextOrderDay.add(settings.numberOfDaysAfterDelivery!, "day")} placeholder="Select date..." value={value || null} disableToolbar onChange={value => pushChange(id, value?.toISOString() || "")} />;
  }
  case QuestionType.CHECKBOX:
    return (
      <Checkboxes
        key={id + (value as string[] || []).join(" ")}
        options={question.availableAnswers || []}
        value={value as string[] || []}
        onChange={value => pushChange(id, value)}
      />
    );
  case QuestionType.CHOICE: {
    const options = question.availableAnswers.map(answer => ({ label: answer.answer, value: answer })) || [];
    return <Select<IAnswer> options={options} value={options.find(option => option.value.id === value)!} onChange={value => pushChange(id, value!.value!.id)} />;
  }
  case QuestionType.RADIO: {
    const options = question.availableAnswers.map(answer => ({ label: answer.answer, value: answer.id })) || [];

    return (
      <Group name={question.id}>
        {options.map((option, i) => (
          <Item
            key={i}
            checked={option.value === (Array.isArray(value) ? value[0] : value)}
            id={option.value}
            label={option.label}
            value={option.value}
            onChange={value => pushChange(id, value as string)}
          />
        ))}
      </Group>
    );
  }
  default:
    return <p>{type}</p>;
  }
};

export default InputRenderer;
