import React, { useCallback, useEffect, useRef, useState } from "react";

import classes from "./Select.module.scss";
import { ReactComponent as ArrowUpSvg } from "assets/images/arrow_up.svg";
import { ReactComponent as ArrowDownSvg } from "assets/images/arrow_down.svg";
import { ReactComponent as CloseSvg } from "assets/images/close.svg";
import { ReactComponent as TickSvg } from "assets/images/tick.svg";

export type Option<T> = {
  label: string;
  value: T;
  key?: string;
}

export type Meta<T> = {
  option: Option<T> | null
  isCleared: boolean
}

interface ISelectProps<T> {
  options: Option<T>[];
  label?: string;
  value: Option<T> | null;
  isClearable?: boolean;
  isMenuAlwaysOpen?: boolean;
  onChange?: (value: Option<T> | null, meta: Meta<T>) => void;
}

function Select<T>({ options, onChange, label, value: initialValue, isClearable = true, isMenuAlwaysOpen = false }: ISelectProps<T>): React.ReactElement {
  const [menuOpen, setMenuOpen] = useState(isMenuAlwaysOpen);
  const [value, setValue] = useState<Option<T> | null>(initialValue);

  const container = useRef<HTMLDivElement>(null);
  const menu = useRef<HTMLDivElement>(null);

  const handleSelectChange = useCallback((option: Option<T> | null) => {
    setValue(option);
    if (onChange) {
      if (option) {
        onChange(option, { option, isCleared: false });
      } else {
        onChange(null, { option: null, isCleared: true });
      }
    }
    if (!isMenuAlwaysOpen) setMenuOpen(false);
  }, [onChange]);

  const handleClearValue = useCallback((e) => {
    e.stopPropagation();
    handleSelectChange(null);
  }, []);

  const toggleMenu = useCallback(() => {
    if (!isMenuAlwaysOpen) setMenuOpen(!menuOpen);
  }, [menuOpen]);

  const handleClickOutside = useCallback((e) => {
    if (isMenuAlwaysOpen) return;

    if (!container.current?.contains(e.target) && !menu.current?.contains(e.target)) {
      setMenuOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className={classes.select}>
      <div ref={container} className={classes.input} onClick={toggleMenu}>
        <span>{isMenuAlwaysOpen ? label : value?.label ?? label}</span>
        <div className={classes.actions}>
          {value && isClearable && <CloseSvg onClick={handleClearValue} />}
          {!isMenuAlwaysOpen ? menuOpen ? <ArrowUpSvg /> : <ArrowDownSvg /> : null}
        </div>
      </div>
      <div ref={menu} className={`${classes.menu} ${menuOpen ? classes.open : ""}`}>
        {options.map((option, i) => (
          <div key={option.key || i} className={classes.option} onClick={() => handleSelectChange(option)}>
            <span>{option.label}</span>
            {option.value === value?.value && (
              <TickSvg />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Select;
