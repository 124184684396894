import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { IQuestionsState } from "models";
import { getQuestionsThunk } from "./thunks/getQuestions.thunk";

export const questionsExtraReducers = (
  builder: ActionReducerMapBuilder<IQuestionsState>
): ActionReducerMapBuilder<IQuestionsState> =>
  builder.addCase(getQuestionsThunk.pending, (state) => {
    state.loadingQuestions = true;
  }).addCase(getQuestionsThunk.fulfilled, (state, action) => {
    state.allQuestions = action.payload.data.sort((a, b) => a.question_priority - b.question_priority);
    state.loadingQuestions = false;
  }).addCase(getQuestionsThunk.rejected, (state, action) => {
    state.loadingQuestions = false;
    state.allQuestions = [];
    console.log(action.error.message);
  });
