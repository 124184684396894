import { ActionReducerMapBuilder } from "@reduxjs/toolkit";

import { IProductsState } from "models";
import { getProductsThunk } from "./thunks/getProducts.thunk";
import { createProductThunk } from "./thunks/createProduct.thunk";
import { updateProductThunk } from "./thunks/updateProduct.thunk";

export const productsExtraReducers = (
  builder: ActionReducerMapBuilder<IProductsState>
): ActionReducerMapBuilder<IProductsState> =>
  builder
    .addCase(getProductsThunk.pending, (state) => {
      state.loadingProducts = true;
    })
    .addCase(getProductsThunk.fulfilled, (state, action) => {
      state.allProducts = action.payload.data;
      state.loadingProducts = false;
    })
    .addCase(createProductThunk.fulfilled, (state, action) => {
      state.finalProduct = action.payload;
    })
    .addCase(updateProductThunk.fulfilled, (state, action) => {
      state.finalProduct = action.payload;
    });
