import React from "react";
import { Button as MButton } from "@mui/material";

import classes from "./Button.module.scss";

interface IButtonProps {
  label: string;
  onClick: () => void
  secondary?: boolean
  big?: boolean
  outlined?: boolean
  className?: string
  disabled?: boolean
}

const Button: React.FC<IButtonProps> = ({ label, onClick, secondary, outlined, className, disabled }) => {
  return (
    <MButton className={`${classes.button} ${className ?? ""} ${secondary ? classes.secondary : ""} ${outlined ? classes.outlined : ""}`} disabled={disabled} onClick={onClick}>
      {label}
    </MButton>
  );
};

export default Button;
