import { IProductsState } from "models";
import { coverTypes } from "../../routes/PersonalizeCover/utils";

export const productsInitialState: IProductsState = {
  selectedType: null,
  selectedCover: null,
  updatedCover: null,
  coverType: coverTypes[1].value,
  allProducts: [],
  loadingProducts: false,
  finalProduct: undefined,
  answers: []
};
