import Spinner from "components/Spinner";
import React from "react";

import classes from "./Loading.module.scss";

interface LoadingProps {
  title?: string
}

const Loading: React.FC<LoadingProps> = ({ title }) => {
  return (
    <div className={classes.container}>
      <h2 className={classes.title}>{title}</h2>
      <Spinner />
    </div>
  );
};

export default Loading;