import { createAsyncThunk } from "@reduxjs/toolkit";

import questionsApi from "api/questionsApi";
import { prefix } from "./config";
import { IPaginatedQuestions } from "shared/lib/models";

export const getQuestionsThunk = createAsyncThunk<IPaginatedQuestions, string>(`${prefix} Get Questions`, async (payload, { rejectWithValue }) => {
  try {
    return await questionsApi.getQuestions(payload);
  } catch (err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
