import { createAsyncThunk } from "@reduxjs/toolkit";

import productsApi from "api/productsApi";
import { prefix } from "./config";
import { ICreateProductPayload, IProduct } from "shared/lib/models";

export const createProductThunk = createAsyncThunk<IProduct, ICreateProductPayload>(`${prefix} Create Product`, async (payload, { rejectWithValue }) => {
  try {
    return await productsApi.createProduct(payload);
  } catch (err) {
    console.log(err);
    return rejectWithValue(err);
  }
});
