import React from "react";
import { Provider } from "react-redux";
import DayjsUtils from "@date-io/dayjs";
import { PersistGate } from "redux-persist/integration/react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import store, { persistor } from "store";
import Routes from "routes";
import "config/i18n";
import "styles/index.scss";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <Routes />
        </MuiPickersUtilsProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
