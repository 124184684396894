import React, { useCallback } from "react";

import classes from "./Checkbox.module.scss";
import { ReactComponent as TickSvg } from "assets/images/tick.svg";

type CheckboxProps = {
  id: string
  name?: string
  label?: string
  onChange: (value: boolean) => void
  checked: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({ id, name, checked, label, onChange: pushChange }) => {

  const handleOnChange = useCallback(e => {
    pushChange(e.target.checked);
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.checkbox}>
        <input checked={checked} className={classes.box} id={id} name={name} type="checkbox" onChange={handleOnChange} />
        <label className={classes.tick} htmlFor={id}>{checked ? <TickSvg /> : ""}</label>
      </div>
      {label && <label htmlFor={id}>{label}</label>}
    </div>
  );
};

export default Checkbox;
