import React from "react";

import classes from "./Group.module.scss";
import { IItemProps } from "./Item";

interface IGroupProps {
  title?: string;
  name: string;
  cols?: number
  children: React.ReactElement<IItemProps> | React.ReactElement<IItemProps>[]
}

const Group: React.FC<IGroupProps> = ({ name, title, cols = 4, children }) => {
  return (
    <div>
      {title && <p className={classes.title}>{ title }</p>}
      <div className={classes.items} style={{ gridTemplateColumns: `repeat(${cols}, 1fr)` }}>
        {Array.isArray(children) ? React.Children.map(children, (item => {
          if (item) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return React.cloneElement(item, { ...item.props, key: item.props.id, groupName: name });
          }
        })) : React.cloneElement(children, {
          ...children.props,
          key: children.props.id,
          groupName: name
        })}
      </div>
    </div>
  );
};

export default Group;
