import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import classes from "./Pages.module.scss";
import paths from "config/paths";
import Navbar from "components/Navbar";
import Button from "components/Button";
import Loading from "components/Loading";
import { RootState } from "store";
import { IElement } from "models";
import { useAppDispatch } from "hooks";
import { ReactComponent as EditSvg } from "assets/images/edit.svg";
import { ITemplate } from "shared/lib/models";
import { orderProductThunk } from "redux/products/thunks/orderProduct.thunk";

const MobilePages: React.FC = () => {
  const [loading, setLoading] = React.useState(false);

  const productId = useSelector<RootState, string | undefined>(state => state.products.finalProduct?.id);
  const finalProduct = useSelector<RootState, ITemplate<IElement>[]>(state => state.products.finalProduct?.pages ?? []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["pages", "navigation", "errors", "pdf"]);

  const handlePurchaseClick = useCallback(async () => {
    setLoading(true);
    if (productId) {
      await dispatch(orderProductThunk({ productId })).unwrap();
      parent.postMessage(JSON.stringify({
        type: "purchase",
        productId
      }), "*");
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (!productId) {
      navigate(paths.questions);
    }
  }, [finalProduct]);

  return (
    <>
      <Navbar currentStep={3} stepInfo={t("stepInfo")} />
      <div className={classes.container}>
        {finalProduct.map((page, i) => (
          <div key={page.id} className={classes.page} onClick={() => navigate(paths.editPage.replace(":pageId", page.page_id))} >
            <img alt={`Page ${i + 1}`} src={page.preview_image} />
            <EditSvg className={classes.editImage} height={50} width={50} />
          </div>
        ))}
      </div>

      <div className={classes.bottom}>
        {/*<Button label={t("navigation:preview")} secondary onClick={() => navigate(paths.finalize)} />*/}
        <div className={classes.nextButton}>
          <Button label={t("navigation:back")} secondary onClick={() => navigate(paths.questions)} />
          <Button label={t("createBook")} onClick={handlePurchaseClick} />
        </div>
      </div>
      {loading && <Loading title={t("loading")} />}
    </>
  );
};

export default MobilePages;