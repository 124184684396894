import { createSlice } from "@reduxjs/toolkit";

import { productsInitialState } from "./products.initialState";
import { productsReducers } from "./products.reducers";
import { productsExtraReducers } from "./products.extraReducers";

export const productsSlice = createSlice({
  name: "products",
  initialState: productsInitialState,
  reducers: productsReducers,
  extraReducers: productsExtraReducers,
});

export const { actions: productsActions } = productsSlice;
